<script setup>
const emit = defineEmits(['prev', 'next'])
defineProps({
  step: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  nextStepTitle: {
    type: String,
    default: 'Volgende stap',
  },
  prevStepTitle: {
    type: String,
    default: 'Vorige stap',
  },
  errorMessage: {
    type: String,
    default: '',
  },
  submitDisabled: {
    type: Boolean,
    default: false,
  },
  prevDisabled: {
    type: Boolean,
    default: false,
  },
})

function submit() {
  emit('next')
}
</script>

<template>
  <section
    class="rounded-3xl border border-gray-200 px-6 py-10 shadow-sm shadow-gray-300 sm:p-12 bg-white w-full my-12"
  >
    <form class="flex h-full flex-col justify-between" @submit.prevent="submit">
      <div class="">
        <div class="flex items-center gap-6">
          <figure
            class="flex h-10 w-10 items-center justify-center rounded-full bg-secondary"
          >
            <span class="font-condensed font-black text-accent">{{
              step
            }}</span>
          </figure>
          <h2
            class="font-heading text-[36px] font-black leading-9 text-secondary uppercase"
          >
            {{ title }}
          </h2>
        </div>

        <div class="mt-10 flex sm:flex-col gap-6 flex-col-reverse">
          <Notice v-if="errorMessage" id="error" type="error" cant-close>
            {{ errorMessage }}
          </Notice>

          <slot />
        </div>
      </div>

      <div
        class="mt-10 flex gap-2"
        :class="{ 'justify-end': step === 1, 'justify-between': step > 1 }"
      >
        <Button
          v-if="step > 1"
          :text="prevStepTitle"
          :disabled="prevDisabled"
          type="button"
          class="w-full grow sm:w-auto"
          @click="emit('prev')"
        />
        <Button
          :text="nextStepTitle"
          :disabled="submitDisabled"
          theme="accent"
          type="submit"
          class="w-full grow sm:w-auto"
        />
      </div>
    </form>
  </section>
</template>
